.nav-item {
  margin: 10px;
}
.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  z-index: 1000;
}

.nav a {
  color: #999;
}

.nav a:hover {
  color: #fb336f;
  text-decoration: none;
}

.home-view {
  max-width: 300px;
  margin: auto;
  text-align: center;
  margin-top: 10%;
}

.home-view img {
  max-width: 150px;
  margin: auto;
  display: block;
}
.home-view a {
  margin: auto;
  display: inline-block;
}
